.introjs-tooltip {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
}

.introjs-button,
.introjs-button:hover,
.introjs-button:focus,
.introjs-button:active,
.introjs-disabled,
.introjs-disabled:focus,
.introjs-disabled:hover {
  outline: none;
  background-image: none;
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 50px;
  box-shadow: none;
  text-shadow: none;
  padding: 5px 9px;
}

.introjs-skipbutton {
  color: #fff !important;
  background-color: #282828;
  transition: all 300ms;
}

.introjs-skipbutton:hover {
  background-color: #222222;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  transition: all 300ms;
}

.introjs-nextbutton {
  background-color: #258faf !important;
  transition: all 300ms;
}

.introjs-nextbutton:hover,
.introjs-nextbutton:active {
  background-color: #008542 !important;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  transition: all 300ms;
}

.introjs-disabled,
.introjs-disabled:focus,
.introjs-disabled:hover {
  color: #ccc;
  border: 1px solid transparent;
}

.introjs-arrow {
  border: 10px solid rgba(0, 0, 0, 0.7);
}

.introjs-arrow.top,
.introjs-arrow.top-middle,
.introjs-arrow.top-right {
  border-color: transparent transparent rgba(0, 0, 0, 0.7);
  top: -20px;
  left: 20px;
}

.introjs-arrow.bottom,
.introjs-arrow.bottom-middle,
.introjs-arrow.bottom-right {
  border-color: rgba(0, 0, 0, 0.7) transparent transparent;
  bottom: -20px;
  left: 20px;
}

.introjs-arrow.left,
.introjs-arrow.right {
  top: 20px;
}

.introjs-arrow.left-bottom,
.introjs-arrow.right-bottom {
  bottom: 20px;
}

.introjs-arrow.left,
.introjs-arrow.left-bottom {
  left: -20px;
  border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
}

.introjs-arrow.right,
.introjs-arrow.right-bottom {
  right: -20px;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.7);
}

/* Multiselect */

.dropdown-menu.show {
  padding-left: 10px;
  padding-right: 10px;
}

#formAlertaOportunidade {
  width: 485px
}

.multiselect-clear-filter {
  padding-top: 4px;
  padding-bottom: 5px;
  height: unset;
}

.multiselect-item.filter .input-group-btn {
  border: 1px solid #ced4da;
  height: 38px;
  border-radius: 0 5px 5px 0;
  margin-left: -1px;
}