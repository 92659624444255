.modalPan {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: none;
  z-index: 9998;
}

.modalPan .wrapper {
  position: fixed;
  left: calc(50% + 15px);
  top: calc(50% - 15px);
  transform: translate(-50%, -50%);
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.35);
  color: #fff;
}

.modalPan .col {
  padding: 30px;
  text-align: center;
}

#loaderGif .wrapper {
  width: 850px;
  height: 452px;
  padding: 5px;
}

.loader {
  border: 16px solid transparent;
  border-top: 16px solid rgba(0, 133, 66, 1);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: relative;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


#loader .wrapper {
  background: transparent !important;
  box-shadow: 0 0 0 0 transparent !important;
}

#loader-image {
  border-radius: 15px;
  border: 5px #fff solid;
  background: #fff;
}

.list-group-item-light.list-group-item-action.clicked {
  background-color: #ccc !important;
}

.list-group-item-light.list-group-item-action.clicked:hover {
  background-color: #ccc;
}

.x {
  float: right;
  margin-top: -20px;
  margin-right: -10px;
  cursor: pointer;
}



@media screen and (max-width: 768px) {

  /* .modalPan .wrapper{
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      margin: 0;
      transform: translate(0);
      border-radius: 0;
    } */
  .modalPan .wrapper {
    width: 100%;
    position: fixed;
    left: 0;
    margin: 0;
    transform: translate(0);
  }
}

#modalDownload .modal-body {
  max-height: 500px;
  overflow-y: auto;
}