html {
  height: 100%;
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: inherit;
}

body {
  padding: 0;
  position: relative;
  margin: 0;
  padding-bottom: 100px;
  min-height: 100%;
  font-family: 'Trebuchet MS', 'Lucida Grande', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

h1 {
  font-size: 2.5vw !important;
  color: #7b7b7b !important;
  text-align: center !important;
  margin: 10px 0 15px 0 !important;
  padding: 0 !important;
  font-weight: 700 !important;
}

a {
  color: #006298;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  line-height: inherit;
}

a:focus,
a:hover {
  color: #258faf;
  outline: none;
}

a:focus,
a:hover {
  color: #258faf;
}

a:active,
a:hover {
  outline-width: 0;
}

.btn-primary {
  background-color: #006198;
}

.hidden {
  display: none !important;
}

.introjs-fixParent {
  position: absolute;
}

.header {
  width: 100%;
  text-align: center;
}

.footer {
  position: absolute;
  bottom: 0;
  padding: 0;
  width: 100%;
  text-align: center;
}

.btnTourTop {
  top: 30px;
  background-color: rgb(150, 150, 150);
  padding: 5px 14px;
  border-radius: 5px;
  color: white;
  margin-top: 15px;
  transition: all 200ms;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  position: absolute;
  right: 0;
  margin-right: 15px;
  transform: translateX(-50%);
  width: 100px;
  text-align: center;
}

.feedback {
  top: 0px;
}

.btnPan {
  border: none;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 1px;
  white-space: nowrap;
  font-family: "Petrobras", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: white;
  background-color: #006298;
  border-radius: 3px;
  padding: 16px 26px;
  line-height: 1.3;
  transition: all 0.8s;
  -ms-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
}

.btnTourTop:hover,
.btnPan:hover {
  background-color: #008542;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.9);
  text-decoration: none;
  color: white;
  transition: all 0.8s;
}

#btnTourTop:hover,
#btnTourCenter:hover {
  text-decoration: none;
}

#beta {
  font-size: small;
  font-weight: bold;
  color: #a3a3a3;
  float: right;
  margin-right: -20px;
}

.introjs-tooltip {
  max-width: inherit;
}

.blur {
  filter: blur(4px) !important;
  transition: all 500ms;
}

.introjs-overlay {
  opacity: 0 !important;
  background-color: transparent;
}

.introjs-helperLayer {
  border: none;
  border-radius: 25px;
  transition: all 300ms;
  opacity: 0.6;
}

#blocoBusca,
.btnPan,
.ais-SearchBox-form,
#painel,
.btnTourTop,
#blocoBusca h1,
#searchbox,
.ais-Hits-list,
#filtros,
#btnTourTop,
#blocoBusca,
#titulo,
#histCons,
#mapa,
#contratos,
#barras,
#downloadBase {
  transition: filter 300ms;
}

#blocoBusca {
  /* filter: blur(0px); */
}

.fix-leaflet-images-404-errors {
  background-image: url(marker-shadow.png);
  background-image: url(marker-icon.png);
  background-image: url(marker-icon-2x.png);
}

.fixotop {
  top: 60px !important;
  position: inherit;
  transition: none;
}

.tooltip {
  z-index: 999999999;
}

.sliderVal {
  padding: 0;
  margin-left: -10px;
  margin-right: 6px;
}

@media screen and (max-width: 768px) {
  .left-panel {
    float: inherit !important;
  }

  .ais-Hits-item {
    width: 100% !important;
  }

  .right-panel {
    margin-left: 0 !important;
  }

  .btnTourTop,
  #btnTourCenter,
  .hint {
    display: none !important;
  }

  #blocoBusca h1 {
    font-size: 1.25em !important;
  }
}