.ais-SearchBox {
  width: 90%;
  padding-bottom: 1em;
}

.ais-SearchBox-input {
  font-size: 1em;
  height: 2.5em;
  border-radius: 20px;
  -moz-box-shadow: 2px 2px 3px 1px #ccc;
  -webkit-box-shadow: 2px 2px 3px 1px #ccc;
  box-shadow: 2px 2px 3px 1px #ccc;
  padding-top: 0.4em;
  font-family: 'Trebuchet MS', 'Lucida Grande', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
}

.ais-SearchBox-input:focus,
.ais-SearchBox-input:hover,
.ais-SearchBox-input:active {
  outline: none !important;
}

.ais-Hits-item {
  border: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: #e2e2e2 solid 1px;
  border-radius: 20px;
  margin-bottom: 1em;
  width: calc(33% - 1rem);
  padding-right: 0.3em;
}

.ais-Hits-item:hover {
  background-color: #e2e2e2;
}

.ais-Hits-item img {
  margin-right: 1em;
}

.algolia-result-item {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 0.8em;
}

.ais-RefinementList-labelText {
  color: #006298;
  font-weight: 600;
  font-size: 14px;
}

.ais-RefinementList-labelText:hover{
  cursor: pointer;
}

.ais-RefinementList-item{
  border-bottom: 1px solid #e5e1dd;
  padding: 12px 0 5px 0;
}

.ais-RefinementList-checkbox{
  vertical-align: middle;
}

.left-panel {
  padding: 14px;
  text-transform: uppercase;
  color: rgba(33, 37, 41);
  font-weight: 600;
  font-family: "Petrobras", sans-serif;
}

#filtros p{
  font-size: 14px !important;
  border-bottom: 1px solid #e5e1dd;
  padding-bottom: 9px;
  width: 100%;
  margin-bottom: 0px !important;
}

.ais-ClearRefinements {
  margin: 1em 0;
}

.ais-SearchBox {
  margin: 2.5em 0;
}

.ais-Pagination {
  margin-top: 1em;
}

.left-panel {
  float: left;
}

.right-panel {
  margin-left: 160px;
}

.ais-InstantSearch {
  max-width: 1160px;
  overflow: hidden;
  margin: 0 auto;
}

.hit-name {
  margin-bottom: 0.5em;
  width: calc(100% - 18px);
}

.hit-description {
  color: #888;
  font-size: 14px;
  margin-bottom: 0.5em;
  text-align: justify;
  width: calc(100% - 18px);
}

#poweredBy{
  float: right;
  margin-top: -30px;
  margin-right: 130px;
}
