/* Blocos de fundo */
.blocao {
  height: 500px;
  border: #e2e2e2 solid 1px;
  border-radius: 20px;
  box-shadow: none;
  transition: box-shadow 500ms;
  padding: 7px;
}

.blocao:hover {
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.25);
}

.hint {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 401;
  display: block;
  height: 25px;
  width: 25px;
  background-color: rgb(180, 180, 180);
  padding: 2px 10px 2px 10px;
  border-radius: 50%;
  color: white;
  box-shadow: none;
  transition: all 200ms;
}

.hint:hover {
  cursor: pointer;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.32);
  transition: all 200ms;
}

#blocoTitulo {
  height: unset;
}

.contrato {
  height: unset;
  border-radius: 0.25em;
  width: calc(100% - 12px);
  border: #e2e2e2 solid 1px;
  padding: 7px;
}

.contrato:hover {
  box-shadow: none;
}

.conteudo {
  background-color: green;
  height: 50px;
  width: 100%;
}

.subrow {
  height: 100%;
  margin: 0;
}

.subrow .conteudo {
  background-color: yellow;
}

.subrow>div {
  padding: 7px;
  height: 100%;
  min-width: 60px;
}

.row>.cbl {
  padding: 7px;
}

.click {
  margin: 0;
  transition: background-color 200ms;
}

.click:hover {
  background-color: #e2e2e2;
  cursor: pointer;
  transition: background-color 300ms;
}

.clicked {
  background-color: #e2e2e2;
}

#mapid,
#mapaOp {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

/* Blocos dos contratos */
.bloco {
  max-width: 500px;
  width: 500px;
  padding: 0px;
  margin-bottom: 15px;
}

.btn {
  /*--- Botões dos blocos */
  height: 40px;
}

.btn-oportunidade {
  width: 160px;
  margin-right: 5px;
}

.btn-anexos {
  width: 42px;
  padding: 6px 10px;
  margin-right: 1px;
}

.ais-SearchBox-reset {
  right: 0.7em;
}

.ais-ClearRefinements-button {
  position: relative;
  float: right;
}

.disabled {
  border: rgb(177, 177, 177) 1px solid;
}

h3 {
  /*--- Título dos blocos */
  font-size: 1.2rem;
  text-align: center;
}

.tabela_data {
  /*--- Bloco de data na tabela */
  vertical-align: middle;
  margin: auto;
  padding: 3px;
  width: 12%;
}

.tabela_empresa {
  /*--- Bloco nome da empresa na tabela */
  vertical-align: middle;
  margin: auto;
  padding: 3px 6px;
  width: calc(53% - 14px);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

/*--- Bloco do botão anexo da tabela */
.tabela_anexos {
  vertical-align: middle;
  margin: auto;
  padding: 0 8px 0 3px;
  width: 11%;
}

.tabela_valor_familia,
.tabela_valor_contrato {
  /*------ Valor total do contrato */
  vertical-align: middle;
  margin: auto;
  padding: 3px;
  width: 12%;
  word-spacing: 64px;
}

.tabela_valor_contrato {
  float: left;
  width: fit-content;
  width: -moz-fit-content;
}

.tabela_valor_familia {
  /*------ Valor família no Contrato */
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

/*------ Mini Gráfico do bloco */
.tabela_bar {
  width: 8px;
  margin: 0px -8px 0px 0;
  height: 75px;
  float: right;
}

.tabela_bartop {
  background-color: rgba(254, 227, 156);
}

.tabela_barbottom {
  background-color: rgba(0, 98, 152);
}

#bloco_consumo .chartjs-render-monitor {
  min-height: 362px;
}

#bloco_contratos_fim .chartjs-render-monitor {
  min-height: 410px;
}

#contratosencerrando_lista {
  height: 440px;
}

.ss-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  float: left;
}

.ss-content {
  height: 100%;
  width: calc(100% + 18px);
  padding: 0 0 0 0;
  position: relative;
  overflow: auto;
  box-sizing: border-box;
  overflow-x: hidden;
}

.ss-content.rtl {
  width: calc(100% + 18px);
  right: auto;
}

.ss-scroll {
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  width: 9px;
  border-radius: 4px;
  top: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.25s linear;
  margin-right: 22px;
}

.ss-hidden {
  display: none;
}

.ss-container:hover .ss-scroll,
.ss-container:active .ss-scroll {
  opacity: 1;
}

.ss-grabbed {
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.leaflet-tooltip {
  background: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 4px;
  color: #fff;
}

.leaflet-tooltip-right:before {
  border-right-color: rgba(0, 0, 0, 0.7);
}

.leaflet-tooltip-left:before {
  border-left-color: rgba(0, 0, 0, 0.7);
}

.bs-tooltip-top {
  z-index: 99999999999 !important;
}

.tooltip-inner {
  white-space: pre-line;
}

.my-label {
  background: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  font-size: 16px;
  font-weight: bold;
  color: rgb(33, 37, 41);
  font-family: 'Trebuchet MS', 'Lucida Grande', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.my-label:before {
  border: none;
}

.disclaimer_footer {
  margin: 40px 0 -30px 0;
  font-size: small;
  color: rgb(150, 150, 150);
  font-style: italic;
}

#chConsumo {
  max-height: 383px;
}

#btnDocPadrao {
  position: relative;
  top: -75px;
  right: -320px;
}

.noScroll {
  overflow: hidden;
}

.noWrap {
  flex-shrink: 0;
}

.text-left {
  text-align: left;
}

.btn.disabled {
  cursor: auto;
}

.btn.disabled:focus {
  box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.0);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #b4b4b4;
  border-color: #b4b4b4;
}

.opData {
  border: #e2e2e2 solid;
  border-width: 0 1px 0 0;
}

.opAnexo {
  max-width: 50px !important;
}

.btn-anexos-mini {
  height: 30px;
  width: 32px;
  padding: 0px 10px;
  margin-right: 1px;
}

.oportunidade {
  padding: 0px;
}

.row>.cOpt {
  padding: 0 7px 7px 7px;
}

#oportunidades_lista {
  height: 440px;
}

#oportunidades_lista .table th,
#oportunidades_lista .table td {
  text-align: left;
}

.blocao-medio {
  height: 250px;
}

.mapaOp {
  height: 100%;
  padding: 0 7px;
}

.rowOp {
  margin-top: 0;
  margin-bottom: -15px;
  height: 100%;
}

.subrowOp {
  margin-top: -7px;
  margin-bottom: -8px;
}

.subrowOp .cbl {
  height: 500px;
}

#blocoOp {
  height: 100%;
  margin: 7px;
}

.tituloAlerta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7px;
  margin-bottom: 0.6em;
}

.tituloAlerta>p {
  font-weight: bold;
  margin-bottom: 0;
}

.btnPetronect {
  font-size: x-small;
}

body {
  min-height: 500px;
}

@media screen and (min-width: 768px) {
  .cNome {
    border: #e2e2e2 solid;
    border-width: 0 1px;
  }

  .opId {
    min-width: 110px !important;
  }
}